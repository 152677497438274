import { ConsoleIcon, HomeIcon, InformationIcon, MarketplaceIcon, MessageIcon } from '@assets'

export const navItems = [
    { path: `https://${window.location.host}/app/games`, title: 'games', icon: <ConsoleIcon />, isTarget: true },
    { path: '/home', title: 'home', icon: <HomeIcon />, isTarget: false },
    { path: '/about', title: 'about', icon: <InformationIcon />, isTarget: false },
    { path: '/contact', title: 'contact', icon: <MessageIcon />, isTarget: false },
    { path: `https://${window.location.host}/app/marketplace`, title: 'marketplace', icon: <MarketplaceIcon />, isTarget: true },
]

export const menuItemLegal = [
    { path: '/privacy-policy', title: 'policy' },
    { path: '/terms-and-conditions', title: 'terms' },
    // { path: PATH_INFORMATION_GAME, title: 'game' },
]
